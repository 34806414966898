// Default theme colors
$theme-colors: (
  "coolblack": #090a0b,
  "spacegrey": #353535,
  "snowwhite": #ffffff,
);

// Default brand colors
$brand-colors: (
  "orangered": #ff5100,
  "greatgold": #f2cb05,
  "greenblue": #389092,
);

$theme-name: "spacegrey";
$brand-name: "greenblue";
$theme-color: map-get($theme-colors, "snowwhite");
$brand-color: map-get($brand-colors, "orangered");

@if map-has-key($theme-colors, $theme-name) {
  $theme-color: map-get($theme-colors, $theme-name);
} @else if str-index($theme-name, "#") == 1 {
  $theme-color: spacegrey;
}

@if map-has-key($brand-colors, $brand-name) {
  $brand-color: map-get($brand-colors, $brand-name);
} @else if str-index($brand-name, "#") == 1 {
  $brand-color: greenblue;
}

$content-width: 1200px;

// Click to top theme
$click-to-top-light-background-color: white;
$click-to-top-light-color: #454545;
$click-to-top-dark-background-color: #34323d;
$click-to-top-dark-color: #bbb;

// Selection styles
$selection-color: unquote("");
$selection-background-color: unquote("");

@if $selection-color == "" {
  $selection-color: inherit;
}
@if $selection-background-color == "" {
  $selection-background-color: rgba(invert($brand-color), 0.3);
}

@import "yat";

.post .post-content img, .post .post-content svg, .post .post-content iframe {
  margin-left: 0;
  margin-right: auto;
}

.post .post-content h2, .post .post-content h3, .post .post-content h4, .post .post-content h5, .post .post-content h6 {
  margin: 30px 0 19px;
}

.post .post-header {
  margin: 50px auto 40px;
  padding: 0 0 20px;
  border-bottom: 1px solid #ebebeb;
}

body {
  font-size: 15px;
}
